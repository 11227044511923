import { get } from '@/utils/http.js';

/*
function:获取成绩信息(没有token)
author:sjy
time:2021/03/16
*/

export function getGrade(data: any) {
    return get('/achievement/web/myscore', data);
}
