

















































import {Component, Vue} from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import {getGrade} from '@/api/myGrades/ myGrades';
import myFooter from '@/components/Footer.vue';

@Component({
  components: {
    myHeader,
    myFooter
  }
})
export default class MyGrades extends Vue {
  data = {
    stuId: 0,
    majorId: 0,
    student: []
  };

  loading = true;
  total = 0;
  gradeList = {};
  // // 各学期成绩
  gradeOne = {};
  gradeTwo = {};
  gradeThree = {};
  gradeFour = {};
  gradeFive = {};

  created() {
    this.getData();
  }

  // 方法
  getData() {
    let obj = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      stu_id: 0,
      major: 0
    };
    obj = JSON.parse(localStorage.getItem('student') as string);
    this.data.stuId = obj.stu_id;
    this.data.majorId = obj.major;
    //  this.data.student = JSON.parse(localStorage.getItem('student') as string);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getGrade(this.data).then((res: any) => {
      if (res.code === 200) {
        this.total = res.data.length;
        this.gradeList = res.data;
        this.gradeOne = res.data[0];
        this.gradeTwo = res.data[1];
        this.gradeThree = res.data[2];
        this.gradeFour = res.data[3];
        this.gradeFive = res.data[4];
      } else {
        this.$message.error(res.error);
      }
      this.loading = false;
    });
  }
}
